import './App.css';
import React from 'react';
import Home from "./components/Home";
import Result from "./components/Result";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/result/:id" element={<Result />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
