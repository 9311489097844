import React from 'react';
import './Home.css';
import { Table, Button, Modal, InputGroup, Form, FormControl } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';

const Home = () => {
    const [ entries, setEntries] = React.useState([]);
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    React.useEffect( () => {
        getCrawlerEntries();
    }, [])

    const getCrawlerEntries = async () => {
        const requestOptions = {
            method: "GET"
        }
        const response = await fetch("/api/crawler/list", requestOptions);
        if(!response.ok){
            console.log("ERROR GETTING CRAWLER ENTRIES", response.status);
        }else{
            const data = await response.json();
            // console.log("EUREKA",data)
            setEntries(data);
        }
    }

    
    
    const CrawlerModal = () => {
        const initModalInputs = {
            name: "",
            description: "",
            type: "",
            environment: "",
        }
        const [modalInputs, setModalInputs] = React.useState(initModalInputs);
        const { name, description } = modalInputs
        const onChange = (e) => {
            setModalInputs({ ...modalInputs, [e.target.name]: e.target.value})
        }
        
        const runCrawler = async (event) => {
            event.preventDefault();
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(modalInputs)
            }
            try{
                const response = await fetch("/api/crawler/store", requestOptions);
                const data = await response.json();
                handleClose();
                setEntries([data,...entries])
            }catch(error){
                console.log("FETCH POST ERROR",error)
            }
        }

        return (
            <Modal show={show} onHide={handleClose}>

                <Form onSubmit={runCrawler}>
                <Modal.Header closeButton>
                <Modal.Title>Crawler</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required value={name} name="name" onChange={onChange} type="text" placeholder="Enter name" />
                        {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control required value={description} name="description" onChange={onChange} as="textarea" rows={2}placeholder="Enter description" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formType">
                        <Form.Label>Type</Form.Label>
                        <Form.Select required name="type" onChange={onChange} aria-label="Default select example">
                            <option value="">Select an option</option>
                            <option value="manual">Manual</option>
                            <option value="release">Release</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEnv">
                        <Form.Label>Environment</Form.Label>
                        <InputGroup>
                            <InputGroup.Radio checked aria-label="Radio button for Develop env" id="env-dev" name="environment" value="develop"  onChange={onChange}/>
                            <label htmlFor="env-dev" className="form-control" aria-label="Text input with radio button">Develop</label>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Radio aria-label="Radio button for Staging env" id="env-stage" name="environment" value="staging"  onChange={onChange}/>
                            <label htmlFor="env-stage" className="form-control" aria-label="Text input with radio button">Staging</label>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Radio aria-label="Radio button for Production env" id="env-prod" name="environment" value="production" onChange={onChange}/>
                            <label htmlFor="env-prod" className="form-control" aria-label="Text input with radio button">Production</label>
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button type="submit" variant="primary">
                    Run Crawler
                </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    const convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours   = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        let string = ""
        if (hours   > 0) {string   = string +hours+"h ";}
        if (minutes > 0) {string   = string +minutes+"m ";}
        if (seconds > 0) {string   = string +seconds+"s ";}
        return string;
    }

    const retryCrawler = async (item) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        }
        try{
            const response = await fetch("/api/crawler/retry", requestOptions);
            const data = await response.json();
            // console.log("response retry",data)
            // handleClose();
            setEntries(
                entries.map((item) => {
                    return item.id === data.id ? data: item;
                })
            )
        }catch(error){
            console.log("FETCH POST ERROR",error)
        }
    }

    const lockCrawler = async (item) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        }
        try{
            const response = await fetch("/api/crawler/lock", requestOptions);
            const data = await response.json();
            setEntries(
                entries.map((item) => {
                    return item.id === data.id ? data: item;
                })
            )
        }catch(error){
            console.log("LOCK CRAWLER FETCH ERROR",error)
        }
    }

    const deleteCrawler = async (item) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        }
        try{
            const response = await fetch("/api/crawler/delete", requestOptions);
            const deletedId = await response.json();
            setEntries(
                entries.filter((item) => {
                    return item.id === deletedId ? false : true;
                })
            )
        }catch(error){
            console.log("DELETE CRAWLER FETCH ERROR",error)
        }
    }
  
    return (
    <div className="home">
        <div>
            <h1>Solution Finder Crawler Test Automation</h1>
            <div className="heading">
                <h3>Results</h3>
                <Button variant="primary" onClick={handleShow}>Create Crawler</Button>
            </div>
            <Table striped bordered hover responsive variant="dark">
                <thead>
                    <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Environment</th>
                    <th>Duration</th>
                    <th>File</th>
                    <th>Status</th>
                    <th>Last Solution</th>
                    <th>Error Message</th>
                    <th>Date</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {entries.length > 0 ?
                        entries.map( item => (
                            <tr key={`crawresult-${item.id}`}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.type}</td>
                            <td>{item.environment}</td>
                            <td>{convertHMS(item.duration)}</td>
                            <td>{item.file}</td>
                            <td>{item.status}</td>
                            <td>{item.last_solution}</td>
                            <td>{item.error}</td>
                            {/* <td>
                                {moment().diff(moment(item.created_at), 'days') < 7 ? 
                                    moment(item.created_at).fromNow()
                                : moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')
                                }
                            </td> */}
                            <td>
                                {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                            </td>
                            <td>
                                { item.status === "completed" ?
                                    <Button variant="warning" onClick={ () => lockCrawler(item)}>
                                        Lock
                                    </Button>
                                    : null 
                                }
                                { item.status === "incompleted" ? 
                                    <Button variant="warning" onClick={ () => retryCrawler(item)}>
                                        Retry
                                    </Button>
                                    : null 
                                }
                                { item.status === "completed" || item.status === "incompleted" || item.status === "locked" ? 
                                    <Link to={`/result/${item.id}`}>
                                        <Button variant="info">View</Button>
                                    </Link>
                                    : null
                                }
                                { item.status === "completed" || item.status === "incompleted" || item.status === "uploadfile-failed" ? 
                                    <Button variant="danger" onClick={ () => deleteCrawler(item)}>
                                        Delete
                                    </Button>
                                    : null 
                                }
                            </td>
                            </tr>
                        ))
                    :
                        <tr>
                            <td colSpan="11">There is no tests done yet</td>
                        </tr>
                    }
                </tbody>
            </Table>
            <CrawlerModal />
        </div>
    </div>

    );
}

export default Home;
