import React from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import { Tree } from "../d3/Tree.js";
import { useParams } from "react-router-dom";

const blankSolution = {
  height: 0,
  children: [],
  data: {
    "name": "",
    "breadcrumb" : [],
    "url": "",
    "benefits": [],
    "products": [],
    "conMod": []
  }
}

const Result = () => {
  const { id } = useParams();
  const treeGraphRef = React.useRef(null);
  const treeGraphReverseRef = React.useRef(null);
  const [ jsonData, setJsonData] = React.useState({});
  const [ industries, setIndustries] = React.useState(["All"]);
  const [ solution, setSolution ] = React.useState(blankSolution);
  const [showLeftBar, setShowLeftBar] = React.useState(false);
  const [showBottomBar, setShowBottomBar] = React.useState(false);

  React.useEffect( () => {
    getEntryDetail()
  }, [])

  React.useEffect( ()=> {
    if(jsonData.children){
      const industriesArray = jsonData.children.map( item => item.name);
      setIndustries(industriesArray)
      insertGraphic(jsonData);
    }
  },[jsonData])
  
  const getEntryDetail = async () => {
    try{
      const response = await fetch(`/api/crawler/${id}`);
      const data = await response.json();
      // Get json file from aws bucketeer
      const response2 = await fetch(`/api/crawlerjson/${data.file}`);
      const data2 = await response2.json();
      // console.log("EUREKA JSON",data)
      setJsonData(data2);
    }catch(e){
      console.log("ERROR GETTING CRAWLER ENTRY", e);
    }
  }

  

  const filterData = (e) => {
    const element = e.target;
    if(element.value === "All"){
      insertGraphic(jsonData);
      return;
    }
    const industry = jsonData.children.find( item => item.name === element.value);
    insertGraphic({name:"Solutions",children:[industry]});
  }

  const insertGraphic = (data) => {
    const graphicSVG = Tree(data, { 
      label: d => d.name,
      clickable:  (d, n) => {
        n.data.breadcrumb = buildBreadcrumb(n);
        setSolution(n);
        setShowLeftBar(true);
      }
    });
    treeGraphRef.current.innerHTML = "";
    treeGraphRef.current.appendChild(graphicSVG);
  }

  const insertReverseGraphic = (data) => {
    const graphicSVG = Tree(data, { 
      label: d => d.name,
    });
    treeGraphReverseRef.current.innerHTML = "";
    treeGraphReverseRef.current.appendChild(graphicSVG);
  }

  const buildBreadcrumb = (node) => {
    let breadcrumb = [];
    let tempNode = node;
    while(tempNode){
      breadcrumb.push(tempNode);
      tempNode = tempNode.parent;
    }
    breadcrumb.reverse()
    // console.log("breadcrumb Node array",breadcrumb);
    return breadcrumb
  }

  const generateReverseJSON = () => {
    // // searchTree(jsonData,solution)
    // let stack = []
    // let nodeX, ii;
    // let copyy = JSON.parse(JSON.stringify(jsonData));
    // console.log("....",copyy);
    // // return copyy;
    // // var copy = deepCopy(jsonData);
    // stack.push(copyy);
    // console.log("...",stack.length)
    // while (stack.length > 0) {
    //   console.log("jiji");
    //   nodeX = stack.pop();
    //   if (nodeX.name == solution) {
    //       // Found it!
    //       console.log("FOUNDIT",nodeX)
    //       return nodeX;
    //   } else if (nodeX.children && nodeX.children.length) {
    //       for (ii = 0; ii < nodeX.children.length; ii += 1) {
    //           stack.push(nodeX.children[ii]);
    //       }
    //   }
    // }
    // console.log("solution",solutionn);
    // let solutionn = "Simplify the Admissions and Enrollment Process"
    const solutionName = solution.data.name
    let reverseJson = { name:"", children: []}
    jsonData.children.forEach(industry => {
      // debugger;
      if(industry.name !== "Public Sector"){
        industry.children.forEach(role => {
          role.children.forEach(bt => {
            bt.children.forEach(solutionItem => {
              if(solutionItem.name === solutionName){
                reverseJson.name = solutionName;
                // let nodeXX;
                // let xdxd = [industry.name,role.name,bt.name]
                let hasBT
                let BTX = reverseJson.children.find( item => {
                  if(item.name === bt.name){
                    hasBT = true;
                    return true;
                  }
                  }) || {name: bt.name, children: []}
                if(!hasBT) reverseJson.children.push(BTX);

                let hasRole
                let RoleX = BTX.children.find( item => {
                  if(item.name === role.name){
                    hasRole = true;
                    return true;
                  }
                  }) || {name: role.name, children: []}
                if(!hasRole) BTX.children.push(RoleX);

                let hasIndustry
                let IndustryX = RoleX.children.find( item => {
                  if(item.name === industry.name){
                    hasIndustry = true;
                    return true;
                  }
                  }) || {name: industry.name, children: []}
                if(!hasIndustry) RoleX.children.push(IndustryX);

              }
            })
          })
        })
      }else{
        industry.children.forEach(role => {
          role.children.forEach(solutionItem => {
            if(solutionItem.name == solutionName){
              reverseJson.name = solutionName;

              let hasRole
              let RoleX = reverseJson.children.find( item => {
                if(item.name === role.name){
                  hasRole = true;
                  return true;
                }
                }) || {name: role.name, children: []}
              if(!hasRole) reverseJson.children.push(RoleX);

              let hasIndustry
              let IndustryX = RoleX.children.find( item => {
                if(item.name === industry.name){
                  hasIndustry = true;
                  return true;
                }
                }) || {name: industry.name, children: []}
              if(!hasIndustry) RoleX.children.push(IndustryX);
            }
          })
        })
      }
    });
    return reverseJson;
  }

  // function searchTree(element, matchingName){
  //   const xd = [];
  //   if(element.name == matchingName){
  //        xd.push(element.name);
  //   }else if (element.children != null){
  //        var i;
  //        var result = null;
  //        for(i=0; result == null && i < element.children.length; i++){
  //             result = searchTree(element.children[i], matchingName);
  //        }
  //        return result;
  //   }
  //   return xd;
  // }

  const buildBottomBarContent = () => {
    const reverseJSONBySolution = generateReverseJSON()
    insertReverseGraphic(reverseJSONBySolution);
  }
  return (
      <div>
        <div className="form-file">
          <select name="industry" onChange={filterData}>
            <option key="opt-All" value="All">All</option>
            {industries.map( item => (
              <option key={`opt-${item}`} value={item}>{item}</option>
            ))}
          </select>
        </div>
        <div className="graphic" ref= {treeGraphRef}>
          
        </div>
        <Offcanvas show={showLeftBar} onHide={() => setShowLeftBar(false)} scroll={true} backdrop={false}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {solution.data.breadcrumb.map( (item,index) => (
                <span key={`path-${index}`} className="breadcrumb-path" onClick={ () => {
                  item.data.breadcrumb = buildBreadcrumb(item);
                  setSolution(item)
                }}>
                  {item.data.name}
                </span>
              ))}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* Check if its the last node */}
            {solution.height === 0 ? (
              <>
                <div className="buttons-wrapper">
                  <a className="outline-brand solution-link" href={solution.data.url} target="_blank" rel="noreferrer">Link to Results Page</a>
                  <button className="outline-brand solution-others" onClick={() => setShowBottomBar(true)}>See where else this solution is used</button>
                </div>
                <h4>Benefits</h4>
                <ul>
                {solution.data.benefits && solution.data.benefits.map( (item,index) => (
                  <li key={`benf-${index}`}>{item}</li>
                ))}
                </ul>
                <h4>Products</h4>
                <ul>
                {solution.data.products && solution.data.products.map( (item,index) => (
                  <li key={`prod-${index}`}>{item}</li>
                ))}
                </ul>
                <h4>Conditional Modules</h4>
                <ul>
                {solution.data.conMod && solution.data.conMod.map( (item,index) => (
                  <li key={`cm-${index}`}>{item.title}({item.eyebrow})</li>
                ))}
                </ul>
              </>
            ) : (
              <ul>  
                {solution.children.map( (item,index) => (
                  <li className="d3-tree-text" key={`prod-${index}`} onClick={ (e) => {
                    item.data.breadcrumb = buildBreadcrumb(item);
                    setSolution(item)
                  }}>
                    {item.data.name}
                  </li>
                ))}
              </ul>
            )}
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas show={showBottomBar} onHide={() => setShowBottomBar(false)} onEntered={buildBottomBarContent} placement="bottom">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Reverse Graph-Tree
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="graphic" ref= {treeGraphReverseRef}>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
  );
}

export default Result;
